html{
  min-height: 100%;
  /*padding-bottom: 50px !important;*/
}

body{
  background: linear-gradient(#3B8598, #54AC7D );
  background-repeat: no-repeat;
  background-size: cover;

}

#root{
	overflow-y: scroll !important;
}

.material-icons{
  position: relative;

  top: 7px;
}

.rn-backgroundColor-1oox17p{

}
.rn-backgroundColor-ysccxm{
  background-color: "#B7F9D8 !important";
}
.MuiInputLabel-outlined-103.MuiInputLabel-shrink-100, 
.MuiInputLabel-outlined-711.MuiInputLabel-shrink-708 {
    transform: translate(14px, -12px) scale(0.75) !important;
}

textarea{
	border: none !important;
	padding-top: 15px !important;
}
